import './App.scss';
import logo from "./redaLogo.png"
import ill from "./build.png"
import { useEffect, useState } from 'react';
import AboutMe from './components/AboutMe';

const height = window.innerHeight

function App() {

  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(prevValue => (prevValue === 0 ? 70 : 0)); // Toggle between 0 and 50
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="full">
      <div className='main' style={{height: height}}>
        <div className='navBar'>
          <div className='logo'>
            <img src={logo} alt='logo'/>
          </div>
        </div>

        <div className='c'>
          <div className='me'>
            <div className='personalInfo'>
              <div className='meDesc'>
                <div className='ContainerName'>
                  <span className='name'>Engineering the Future: Big Data and Cloud by Day, ML and Cyber by Heart 💡💻</span>
                </div>

                <div className='ContainerAboutMe'>
                  <span className='aboutMe'>Hi! I'm Reda Elmarrakchy!  </span>
                </div>

                <div className='socials'>
                  <i class='bx bxl-linkedin'></i>
                  <i class='bx bxl-github'></i>
                  <i class='bx bxl-gmail'></i>
                </div>
              </div>

              <div className='myPic'>
                <div className='box' style={{'--rand-value': value}}>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='c'>
          <div className='titleContainer'>
            Discover more about me
          </div>
      </div>

      <AboutMe></AboutMe>
    </div>
  );
}

export default App;

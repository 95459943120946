import './App.scss';
import logo from "./redaLogo.png"


function LogoPage() {
    const height = window.innerHeight

    return (
        <div className='logoPresenter' style={{height: height}}>
            <div className='c'>
                <div className='logoPresenterContainer'>
                    <img src={logo} alt='logo' />

                    <span className='quote'><span style={{color: "red"}}>"Where energy doesn't die"</span></span>
                </div>
            </div>
        </div>
    )
}

export default LogoPage;
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LogoPage from './LogoPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

function RootComponent() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time with setTimeout
    const timer = setTimeout(() => setLoading(false), 1500); // Adjust time as needed
    return () => clearTimeout(timer);
  }, []);

  return loading ? <LogoPage /> : <App />;
}

root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import {useRef, useState } from 'react';
import photo from '../construction.png'
import '../App.scss';

function AboutMe() {
    const [isClicked, setIsClicked] = useState(false);
    const endOfPageRef = useRef(null);

    const handleClick = () => {
        setIsClicked(true)
        console.log(endOfPageRef.current)
        endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }


    return (
        <div className='cellContainer'>
            <div className='c'>
                <div className='cell'>
                    <div className='exectablePart'>
                        <i onClick={handleClick} className='bx bx-play-circle'></i>
                        <i
                            className='bx bx-expand-horizontal'
                            >
                        </i>
                    </div>

                    <div className='codePart'>
                        <div className='codeHolder'>
                            <span className='import'>import</span> redachy <br />
                            <br />
                            redachy.about_me<span style={{color: "blue"}}>()</span><br />
                        </div>
                    </div>
                </div>

                {isClicked ? (<div className='aboutMeDiv'>
                    <img src={photo}/>
                </div>) : ""}
            </div>

            <div className='end' ref={endOfPageRef}></div>
        </div>
    )
}

export default AboutMe;